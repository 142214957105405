import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { modalFadeInOutAnimation } from '../animations/modal-fade-in-out.animation';

@Component({
    selector: 'app-modal-content-container',
    template: `
        <div class="fixed inset-0 flex items-center justify-center" (mousedown)="onDismiss()">
            <div (mousedown)="$event.stopPropagation()" [class.w-full]="!centered" [class.h-full]="!centered">
                <ng-container #contentRef></ng-container>
            </div>
        </div>
    `,
    styleUrls: ['./modal-content-container.component.scss'],
    animations: [modalFadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ModalContentContainerComponent implements OnInit {
    @ViewChild('contentRef', { static: true, read: ViewContainerRef }) public contentRef!: ViewContainerRef;
    @HostBinding('@fadeInOut') fadeInOutBinding: any;
    @Output('close') close = new EventEmitter();
    @Output('dismiss') dismiss = new EventEmitter();

    @Input() centered: boolean = false;

    @HostListener('window:keyup.escape') escapeEvent() {
        this.dismiss.emit();
    }

    constructor() {}

    ngOnInit(): void {}

    onClose() {
        this.close.emit();
    }

    onDismiss() {
        this.dismiss.emit();
    }
}
