import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-main-wrapper',
    template: `
        <main class="flex justify-center h-svh">
            <ng-content></ng-content>
        </main>
    `,
    styleUrls: ['./main-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [],
})
export class MainWrapperComponent implements OnInit {
    ngOnInit() {}
}
