import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-button',
    template: `
        @if (!link) {
            <button
                [type]="type"
                class="btn group/button cursor-pointer"
                [class]="class"
                [class.w-full]="fullWidth"
                [class.btn-outline]="outline"
                [class.square]="square"
                [class.unequal-border]="unequalBorder"
                [disabled]="disabled"
                [class.whitespace-nowrap]="!wrap"
                [id]="id">
                <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            </button>
        }

        @if (link) {
            <a
                class="btn group"
                [class]="class"
                [class.w-full]="fullWidth"
                [routerLink]="link"
                [fragment]="fragment"
                [class.unequal-border]="unequalBorder"
                [id]="id"
                [class.btn-outline]="outline">
                <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            </a>
        }

        <ng-template #contentTpl>
            <ng-content></ng-content>
            @if (loading) {
                <fa-icon [icon]="faSpinner" animation="spin" class="ml-2"></fa-icon>
            }
        </ng-template>
    `,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet, RouterLink, FaIconComponent],
})
export class ButtonComponent {
    faSpinner = faSpinner;

    @Input() color: 'primary' | 'white' | 'blue' | 'green' | 'red' | 'beige' | 'orange' | 'transparent' = 'primary';
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
    @Input() fullWidth: boolean = false;
    @Input() rounded: 'rounded-lg' | 'rounded-full' = 'rounded-lg';
    @Input() square = false;
    @Input() unequalBorder = false;
    @Input() link?: any[];
    @Input() fragment?: string;
    @Input() type: string = 'button';
    @Input() disabled: boolean | null = false;
    @Input() wrap = true;
    @Input() loading: boolean | null = false;
    @Input() id: string | null = null;
    @Input() outline = false;

    @HostBinding('class.w-full') get fullWidthBinding() {
        return this.fullWidth;
    }

    get class() {
        return `btn-${this.color} ${this.rounded} btn-${this.size}`;
    }
}
